import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: '/',
    name: 'invalid',
    component: () => import(/* webpackChunkName: "invalid" */ "../views/Invalid.vue"),
  },
  {
    path: '/building/:virtual',
    name: 'virtual',
    component: () => import(/* webpackChunkName: "virtual" */ "../views/Virtual.vue"),
  },
  {
    path: "/call",
    name: "call",
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/Call.vue"),
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

